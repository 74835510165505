/* Interface showing a white square with a side of 300px in the middle of a screen with a red dot with diameter 5px inside in the middle. The dot can be moved using a mouse (either dragged, or set to another position on click). Below the square the dot's coordinates are shown, normalized to the range [0, 100]x[0, 100]. When a dot is moved, a message with the dot's coordinates is sent to the backend over a websocket connection that is established on page load. */

import React from 'react';
import {useState, useEffect} from 'react';
import {useRef} from 'react';
import {useCallback} from 'react';
import {useLayoutEffect} from 'react';
import useWebSocket from 'react-use-websocket';
import {MIDIVal, MIDIValOutput} from "@midival/core";
import * as MIDI from 'midicube';
import {useLocalStorage} from "react-use";

import './index.css';

const squareSize = 300;
const controlDotRadius = 5;
const curStateDotRadius = 3;

const squareStyle = {
  position: 'relative',
  userSelect: 'none',
  width: squareSize,
  height: squareSize,
  backgroundColor: 'white',
  border: '1px solid black',
  margin: '0 auto',
  marginTop: '40px',
  marginBottom: '40px'
};

const controlDotStyle = {
  position: 'absolute',
  width: controlDotRadius * 2,
  height: controlDotRadius * 2,
  borderRadius: controlDotRadius,
  backgroundColor: 'red',
  zIndex: 2
};

const currentStateDotStyle = {
  position: 'absolute',
  width: curStateDotRadius * 2,
  height: curStateDotRadius * 2,
  borderRadius: curStateDotRadius,
  backgroundColor: 'blue',
};

const App = () => {
  const [controlCoords, setControlCoords] = useState([50, 50, true]);
  const [purePerformances, setPurePerformances, _] = useLocalStorage('purePerformances', false);

  const [currentCoords, setCurrentCoords] = useState([50, 50]);
  const [timeControlUpdated, setTimeControlUpdated] = useState(new Date().getTime());
  const [isMuted, setIsMuted] = useState(false);
  const [dt, setDt] = useState(0);

  const [musicDescription, setMusicDescription] = useState('');
  const [remoteMusicDescription, setRemoteMusicDescription] = useState('');
  const [musicDescriptionFade, setMusicDescriptionFade] = useState({'fade': 'fade-in'});

  const [isDragging, setIsDragging] = useState(false);
  const squareRef = useRef(null);

  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [useWebAudio, setUseWebAudio] = useState(false);
  const [webAudioLoaded, setWebAudioLoaded] = useState(false);
  const [lastMidiEvent, setLastMidiEvent] = useState();
  const [lastMidiEventTime, setLastMidiEventTime] = useState(0);

  const domain = window.location.hostname === 'localhost' ? 'ws://localhost' : 'wss://ws.performance-control.tuttitempi.com';
  const {
    sendMessage,
    readyState,
    getWebSocket
  } = useWebSocket(domain + ':8082/ws', {shouldReconnect: (closeEvent) => true});

  useEffect(() => {
    if (readyState === 1 && controlCoords[2]) {
      sendMessage(JSON.stringify(
          {
            x: controlCoords[0], y: controlCoords[1],
            pure: purePerformances || undefined,
            type: 'tempo_velocity_control'
          }));
      setControlCoords([controlCoords[0], controlCoords[1], false]);
    }
  }, [controlCoords, readyState]);

  useEffect(() => {
    if (useWebAudio && !webAudioLoaded) {
      window.MIDI = MIDI;
      MIDI.loadPlugin({
        soundfontUrl: "./soundfont/",
        instrument: "acoustic_grand_piano",
        onerror: console.warn,
        onsuccess: function () {
          console.log('MIDI.js instrument loaded');
          setWebAudioLoaded(true);
        }
      });
    }
  }, [useWebAudio, webAudioLoaded])

  useEffect(() => {
    MIDIVal.connect().then((accessObject) => {
      if (!accessObject || typeof accessObject === 'undefined') return;

      if (accessObject.outputs.length === 0) {
        setUseWebAudio(true);
      }
      setDevices(accessObject.outputs);
      setSelectedDevice(new MIDIValOutput(accessObject.outputs[0]))
    });

  }, []);

  useEffect(() => {
    if (readyState === 1) {
      console.log('set onmessage');
      let ws = getWebSocket();
      ws.onerror = (ev) => {
        console.log(ev)
      }
      ws.onmessage = (event) => {
        let m = JSON.parse(event.data);

        setLastMidiEvent(event.data);
        setLastMidiEventTime(new Date().getTime() / 1000);

        if (isMuted) {
          return;
        }

        if (m.type === 'midi') {
          let dt = new Date().getTime() / 1000. - m.time;
          let wait = 2 - dt;
          wait = 0;
          // console.log(m.time, new Date().getTime() / 1000., dt, wait);

          if (useWebAudio) {
            if (m.pitch > 0) {
              if (m.velocity > 0) {
                window.MIDI.noteOn(0, m.pitch, m.velocity, wait);
              } else {
                window.MIDI.noteOff(0, m.pitch, wait);
              }
            }
          } else if (selectedDevice) {
            setTimeout(() => {
              if (m.pitch > 0) {
                selectedDevice.sendNoteOn(m.pitch, m.velocity);
              } else if (m.pitch === 0) {
                selectedDevice.sendControlChange(64, m.velocity);
              }
            }, wait * 1000);
          }
        } else if (m.type === 'cur_params') {
          setCurrentCoords([m['tempo_f_cur'], m['velocity_f_cur']]);
        } else if (m.type === 'tempo_velocity_control') {
          if (controlCoords[0] !== m.x || controlCoords[1] !== m.y) {
            setControlCoords([m.x, m.y, false]);
          }
        } else if (m.type === 'performance_description') {
          setRemoteMusicDescription(m.text);
          setMusicDescriptionFade({fade: 'fade-in'});
        } else {
          console.log('unknown message type', m)
        }
      };
    }
  }, [selectedDevice, useWebAudio, readyState, isMuted]);

  useEffect(() => {
    if (readyState === 1 && dt !== 0) {
      sendMessage(JSON.stringify({'delta_t': dt, type: 'change_position'}))
      setDt(0)
    }
  }, [dt, readyState])

  const handleDescriptionSubmission = useCallback(() => {
    if (readyState === 1 && musicDescription.trim().length > 0) {
      sendMessage(JSON.stringify({'text': musicDescription, type: 'performance_description'}));

      setMusicDescription('');
    }
  }, [readyState, musicDescription]);

  useEffect(() => {
    if (musicDescriptionFade.fade === 'fade-in') {
      const timeout = setInterval(() => {
        setMusicDescriptionFade({
          fade: 'fade-out'
        })
      }, 4000);
      return () => clearInterval(timeout);
    }
  }, [musicDescriptionFade.fade])

  const handleMouseMove = useCallback(
      (e) => {
        if (isDragging) {
          const {left, top} = squareRef.current.getBoundingClientRect();
          const newX = Math.min(
              Math.max(e.clientX - left - controlDotRadius, 0),
              squareSize - controlDotRadius * 2
          );
          const newY = Math.min(
              Math.max(e.clientY - top - controlDotRadius, 0),
              squareSize - controlDotRadius * 2
          );

          let x = Math.round((newX / (squareSize - controlDotRadius * 2)) * 100);
          let y = Math.round((newY / (squareSize - controlDotRadius * 2)) * 100);
          setControlCoords([x, y, true]);
        }
      },
      [isDragging]
  );

  const handleClick = useCallback(
      (e) => {

        const {left, top} = squareRef.current.getBoundingClientRect();
        const newX = Math.min(
            Math.max(e.clientX - left - controlDotRadius, 0),
            squareSize - controlDotRadius * 2
        );
        const newY = Math.min(
            Math.max(e.clientY - top - controlDotRadius, 0),
            squareSize - controlDotRadius * 2
        );

        let x = Math.round((newX / (squareSize - controlDotRadius * 2)) * 100);
        let y = Math.round((newY / (squareSize - controlDotRadius * 2)) * 100);
        setControlCoords([x, y, true]);

        setTimeControlUpdated(new Date().getTime());
      },
      []
  );

  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
    }
  }, [isDragging]);

  useLayoutEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  return (
      <div>

        <div style={{"width": 300, "margin": "20px auto 0"}}>
          <h2>Click on the square to change the performance</h2>
        </div>

        <div
            ref={squareRef}
            style={squareStyle}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
          <div style={{
            ...currentStateDotStyle,
            left: currentCoords[0] * (squareSize - curStateDotRadius * 2) / 100,
            top: currentCoords[1] * (squareSize - curStateDotRadius * 2) / 100
          }}/>
          <div style={{
            ...controlDotStyle,
            left: controlCoords[0] * (squareSize - controlDotRadius * 2) / 100,
            top: controlCoords[1] * (squareSize - controlDotRadius * 2) / 100,
            backgroundColor: 'red'
          }}/>
          <div style={{'position': 'absolute'}}>slow & quiet</div>
          <div style={{'position': 'absolute', 'bottom': 0}}>slow & loud</div>
          <div style={{'position': 'absolute', 'bottom': 0, 'right': 0}}>fast & loud</div>
          <div style={{'position': 'absolute', 'top': 0, 'right': 0}}>fast & quiet</div>
        </div>

        <div style={{"width": 300, "margin": "0 auto 0"}}>
          <label>Seek</label>
          <input type="button" value={'<'} onClick={() => setDt(-10)}/>
          <input type="button" value={'>'} onClick={() => setDt(10)}/>
        </div>

        <div style={{"width": 300, "margin": "0 auto 0"}}>
          <label>Pure performances</label>
          <input type="checkbox" checked={purePerformances} onChange={() => {
            setPurePerformances(!purePerformances);
          }}/>
        </div>

        <div style={{"width": 300, "margin": "20px auto 0"}}>
          <input type={'text'}
                 value={musicDescription}
                 onChange={(event) => setMusicDescription(event.target.value)}
                 onKeyDown={(event) => event.key === 'Enter' && handleDescriptionSubmission()}
                 placeholder={'Describe the performance at this moment'}
                 enterKeyHint={'enter'}
                 style={{'width': '98%'}}
          />

          <div>
            <input type={'button'}
                   value={'Submit description'}
                   onClick={handleDescriptionSubmission}
                   disabled={readyState !== 1 && musicDescription.trim().length > 0}
                   title={readyState !== 1 ? 'Cannot submit without a connection' : 'Describe the performance'}/>

            <input type={'button'}
                   value={'Play like this'}
                   disabled={true}
                   title={'Control not implemented yet'}/>
          </div>
          <h4 style={musicDescriptionFade.fade === 'fade-in'
              ? {opacity: 1, transition: 'opacity 1s ease'}
              : {opacity: 0, transition: 'opacity 5s ease'}}>{remoteMusicDescription}</h4>
        </div>

        <div style={{"width": 300, "margin": "20px auto 0"}}>
          <h3>MIDI output</h3>
          <div>
            <label>Mute output</label>
            <input type="checkbox" checked={isMuted} onChange={() => setIsMuted(!isMuted)}/>
          </div>
          <div>
            <label>Use WebAudio (worse sound quality)</label>
            <input type="checkbox" checked={useWebAudio} onChange={() => setUseWebAudio(!useWebAudio)}/>
          </div>
          <div>
            <label>MIDI output device: </label>
            <select onChange={(event) => setSelectedDevice(devices[event.target.value])}>
              {devices.map((device, i) => <option key={i} value={i}>{device.name}</option>)}
            </select>
            {devices.length === 0 && <div>
              You may want to use <a href={'https://www.nerds.de/en/download.html'}>LoopBe</a> to access a piano VST
              from the browser.
            </div>}
            <div>If you don't have a good piano VST, try <a href={'https://www.modartt.com/try'}>Pianoteq</a></div>
          </div>

          <div style={{"marginTop": 20}}>
            <h3 style={{"color": readyState === 1 ? 'green' : 'red'}}>{readyState === 1 ? 'Backend connected' : 'Connecting...'}</h3>
            <div>{lastMidiEventTime > new Date().getTime() / 1000 - 10 ? lastMidiEvent : ''}</div>
          </div>
        </div>


      </div>
  );
};

export default App;